import stringify from 'json-stringify-safe';
import type { IHttpError } from '@wix/http-client';
import {
  CheckoutDataDecodeError,
  StatusDataDecodeError,
  IntegrationDataDecodeError,
} from '@wix/pricing-plans-router-utils';
import { HttpClient } from '@wix/yoshi-flow-editor';
import { MessageCode } from '../types/common';

export class PurchaseLimitExceededError extends Error {
  constructor() {
    super('Purchase limit exceeded.');
    Object.setPrototypeOf(this, PurchaseLimitExceededError.prototype);
  }
}

export function errorToMessage(e: Error): MessageCode {
  if (e instanceof PurchaseLimitExceededError) {
    return MessageCode.PURCHASE_LIMIT_ERROR;
  }
  if (e instanceof CheckoutDataDecodeError) {
    return MessageCode.CHECKOUT_PAYLOAD_DECODE_FAILED;
  }
  if (e instanceof StatusDataDecodeError) {
    return MessageCode.STATUS_PAYLOAD_DECODE_FAILED;
  }
  if (e instanceof IntegrationDataDecodeError) {
    return MessageCode.INTEGRATION_DATA_DECODE_FAILED;
  }
  return MessageCode.UNKNOWN_ERROR;
}

export function messageToText(message: MessageCode): string {
  switch (message) {
    case MessageCode.PURCHASE_LIMIT_ERROR:
      return 'pp.1-purchase-plan-error';
    case MessageCode.PLAN_NOT_FOUND:
      return 'pp.checkout-missing-plan';
    case MessageCode.CHECKOUT_DEMO:
      return 'pp.demo-checkout-message';
    default:
      return 'pp.generic-purchase-error';
  }
}

export function isMessageInformational(message: MessageCode) {
  switch (message) {
    case MessageCode.CHECKOUT_DEMO:
      return true;
    default:
      return false;
  }
}

export const toError = (e: unknown): Error =>
  e instanceof Error ? e : new Error(typeof e === 'string' ? e : stringify(e));

export const getApplicationErrorCode = (e: any) => e?.response?.data?.details?.applicationError?.code;

export const getApplicationErrorData = (e: unknown): Record<string, unknown> | undefined => {
  if (HttpClient.isHttpError(e)) {
    return e.response?.data.details?.applicationError?.data;
  }

  return undefined;
};

export function isWixHttpError(error: unknown): error is IHttpError {
  return Boolean(error && error instanceof Error && (error as any).isWixHttpError);
}

export const isNetworkError = (e: unknown) => isWixHttpError(e) && e.message === 'Network Error';
export const is403 = (e: unknown) => isWixHttpError(e) && e.response?.status === 403;
export const is401 = (e: unknown) => isWixHttpError(e) && e.response?.status === 401;
